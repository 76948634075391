
import React from "react";
import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";
import { pushToGTM, postFBEvent } from "../helpers";

export default ({ location }) => {

    React.useEffect(() => {
        //postToTGM("google_ads_demo_form_submit", "Demo Form Submit");
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Demo Form Submit',
            'resource_name': 'Thank you for requesting a Demo meeting!'
        })
        postFBEvent("Lead", "Demo Form")
    }, []);

    return (
        <Layout title="Thank you for requesting a Demo meeting!" location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-9 is-offset-2">
                                    <h1>Thank you for the Demo meeting request! Please, accept this calendar invitation in your e-mail box to confirm the date and time, and our Sales representative will shortly follow up with the meeting details.</h1>
                                    <Button color="purple" type="outlined" to="/" text="go back home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}